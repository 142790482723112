<template>
  <div class="w-100 header_css " :class="{headerscroll: scrollPosition >1}">
    <b-col
      v-if="!isLoggedIn"
      class="text-right pt-lg-3 pr-lg-4 mr-5 pt-3"
      id="eighth"
    >
      <span class="text-white btn-css menuhoverd mr-2" v-b-modal.modal-1
        ><b-icon icon="lock-fill" aria-hidden="true"></b-icon> Login</span
      >
      <span
        class="text-white menuhoverd pl-2 btn-css mr-5"
        v-b-modal.modal-register
        ><b-icon icon="pencil-square" aria-hidden="true"></b-icon>
        Register</span
      >
    </b-col>
    <b-col
      style="opacity: -1"
      v-else
      class="text-center pt-lg-3 pr-lg-4 mr-5"
      id="eighth"
    >
      <span class="text-white menuhoverd pl-2">Welcome To Expodatabox </span>
    </b-col>
    <b-navbar toggleable="lg" type="dark" class="pt-0">
      <b-navbar-brand href="/" class="pl-lg-5 text-size-logo pl-2"
        ><img src="/img/1st-03.png" height="80"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto text-dark pr-5">
          <b-navbar-nav class="nav-txt1 text-size" id="eighth" pills>
            <b-nav-item class="menuhoverd pb-0" href="/"
              ><span>Home</span></b-nav-item
            >
            <b-nav-item class="menuhoverd" href="/#aboutus"
              ><span>About Us</span></b-nav-item
            >
            <b-nav-item class="menuhoverd" href="/#plans"
              ><span>Pricing</span></b-nav-item>
              
            <b-nav-item class=" bg-pink rounded" href="/#inquiryform"
              ><span><b>Contact Us</b></span></b-nav-item
            >
            <b-nav-item  v-if="isLoggedIn" class="menuhoverd" href="/dashboard"
              ><span>My Dashboard</span></b-nav-item
            >
              <b-nav-item v-if="isLoggedIn" class="menuhoverd" to="/cart"
              > <span>
                <span v-if="this.useridCountCart.cartcount >0" class="cart-counter" style="position: absolute; margin-left: 0.5rem; margin-top: -1rem;font-size:15px">{{this.useridCountCart.cartcount}}</span>
                <b-icon icon="cart4" aria-hidden="true">
                  </b-icon> Cart</span
              ></b-nav-item
            >
            <b-nav-item-dropdown
              id="my-nav-dropdown"
              :text="username"
              toggle-class="nav-link-custom"
              right
              v-if="isLoggedIn"
            >
              <template slot="button-content">
                <b-icon
                  icon="person"
                  class="display-inline user-icon-default"
                  aria-hidden="true"
                ></b-icon>
              
              </template>
             <p>  <!-- {{ username }} --></p>
              <CDropdownItem class="text-primary" to="/profile">
                <CIcon name="cil-user" /> Profile
              </CDropdownItem>

              <CDropdownDivider />
              <CDropdownItem class="text-primary" @click="Logout()">
                <CIcon name="cil-lock-locked" /> Logout
              </CDropdownItem>
            </b-nav-item-dropdown>
          
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-modal id="modal-1" title="Login" hide-footer>
      <div>
        <CForm @submit.stop.prevent="onSubmit">
          <p class="text-muted">Sign In to your account</p>
          <div>
            <div
              v-if="errorMessage != null"
              class="alert alert-danger"
              role="alert"
            >
              {{ errorMessage }}
            </div>
          </div>
          <input
            type="email"
            v-model="v$.details.email.$model"
            placeholder="Registered Email"
            id="email12"
            name="email"
            class="form-control mb-2"
            :state="validateState('email')"
            required
          />
          <CInput
            v-model="details.password"
            placeholder="Password"
            type="password"
            autocomplete="curent-password"
            required
          >
          </CInput>
          <CRow>
            <CCol col="6" class="text-left">
              <b-button type="submit" variant="primary">LogIn</b-button>
              <span v-if="loading == true"><LoadingBar /></span>
            </CCol>
            <CCol col="6" class="text-right">
              <CButton color="link" class="px-0" to="/forgotpassword"
                >Forgot password?</CButton
              >
            </CCol>
            <span
              >New to Expodatabox?<CButton
                @click="$bvModal.hide('modal-1')"
                color="link "
                class="px-0"
                v-b-modal.modal-register
                >Register here.</CButton
              ></span
            >
          </CRow>
        </CForm>
      </div>
    </b-modal>
    <b-modal id="modal-register" title="Register" hide-footer>
      <div>
        <CForm class="pt-2" @submit.prevent="onSubmitsignup">
          <h4>Create Your Account</h4>
          <p class="text-muted"></p>
          <div>
            <b-alert
              v-if="errorMessage123 != null"
              v-model="showDismissibleAlert"
              variant="danger"
              dismissible
            >
              {{ errorMessage123 }}
            </b-alert>
          </div>
          <div>
            <b-alert
              v-if="errorMessage12345 != null"
              v-model="showDismissibleAlert123"
              variant="success"
              dismissible
            >
              {{ errorMessage12345 }}
            </b-alert>
          </div>
          <div>
            <b-form-group label="Plan Names " v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="signupdetails.selected_radio"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
                required
              >
                <b-form-radio value="trial">Trial</b-form-radio>
                <b-form-radio value="silver">Silver</b-form-radio>
                <b-form-radio value="gold">Gold</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <CInput
            v-model="signupdetails.name"
            placeholder="Your Name"
            autocomplete="Email"
            onkeypress="return /[a-z]/i.test(event.key)"
            required
          >
          </CInput>
          <input
            type="email"
            pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
            v-model="signupdetails.email"
            placeholder="Your Email"
            id="email"
            name="email"
            class="form-control mb-3"
            @keyup="checkUsername()"
            required
          />
          <span class="text-danger">{{ errorMessageEmaiAlready }}</span>
          <div>
            <b-form-select
              v-model="signupdetails.location"
              :options="options"
              required
            ></b-form-select>
          </div>
          <div class="pt-3">
            <b-form-input
              type="number"
              v-model="signupdetails.mobile"
              id="mobile"
              placeholder="Mobile Number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="10"
              minlength="10"
              required
            ></b-form-input>
            <template v-if="v$.signupdetails.mobile.$model">
              <div
                v-for="error of v$.signupdetails.mobile.$silentErrors"
                :key="error.$message"
              >
                <div class="red" style="color: red">
                  {{ error.$message }}
                </div>
              </div>
            </template>
          </div>
          <CInput
            class="pt-3"
            v-model="signupdetails.password"
            placeholder="Password"
            type="password"
            autocomplete="new-password"
            v-on:keyup="hideerrorPass()"
          >
          </CInput>
          <span class="text-danger">{{ errorMessagePasswordInvalid }} </span>
          <template v-if="v$.signupdetails.password.$dirty">
            <div
              v-for="error of v$.signupdetails.password.$silentErrors"
              :key="error.$message"
            >
              <div class="red" style="color: red">
                {{ error.$message }}
              </div>
            </div>
          </template>
          <CInput
            v-model="signupdetails.password_confirmation"
            placeholder="Repeat password"
            type="password"
            autocomplete="new-password"
            v-on:keyup="hideerrorPass()"
            class="mb-4"
          >
          </CInput>
          <span
            style="color: red"
            v-if="
              signupdetails.password_confirmation !== signupdetails.password
            "
          >
            Password Not Match
          </span>
          <!--  -->
          <div class="pb-3">
            <b-form-checkbox
              id="checkbox-1"
              name="checkbox-1"
              value="yes"
              v-model="signupdetails.check_terms"
              required
            >
              I have read the terms and conditions, privacy and other policies
              present on this website and I agree to all of them
            </b-form-checkbox>
            <span
              >Existing User?
              <CButton
                @click="$bvModal.hide('modal-register')"
                color="link "
                class="px-0"
                v-b-modal.modal-1
                >Log in</CButton
              ></span
            >
          </div>
          <b-button
            type="submit"
            variant="primary"
            :disabled="disabledregister == 1"
            >Sign Up</b-button
          >
          <span v-if="loading == true"><LoadingBar /></span>
        </CForm>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import LoadingBar from "../LoadingBar";

export default {
  data() {
   
     console.log(window.location.pathname)
    return {
        scrollPosition: null,
      disabledregister: 0,
      loading: false,
      isAvailable: 0,
      responseMessage: null,
      errorMessageEmaiAlready: null,
      errorMessagePasswordInvalid: null,
      errorMessageVerified: null,
      errorMessage123: null,
      errorMessage12345: null,
      showDismissibleAlert: false,
      showDismissibleAlert123: false,
      errorMessage: null,
      message: "",
      selected: null,
      validationErrors: null,
      options: [
        { value: null, text: "Country" },
        { value: "india", text: "India" },
      ],
      details: {
        email: null,
        password: null,
      },
      signupdetails: {
        name: null,
        email: null,
        mobile: null,
        check_terms: null,
        selected_radio: "trial",
        password: null,
        password_confirmation: null,
        location: null,
        remember_token: null,
      },
      username: null,
      useridCountCart:{
        userid:null,
        cartcount:null,
        token :null,
      }
    };
  },
  validations() {
    return {
      details: {
        email: {
          required,
        },
        password: {
          required,
          minLength: minLength(3),
        },
      },
      signupdetails: {
        mobile: {
          required,
          minLength: minLength(10),
        },
        password: {
          required,
          minLength: minLength(8),
        },
        password_confirmation: {
          required,
        },
      },
    };
  },
  components: {
    LoadingBar,
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");

    },
  },
  created() {
    if (this.isLoggedIn) {
      // this.getUser();
      this.CartCount();
    }
  
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
    
},
  setup() {
    return { v$: useVuelidate() };
  },
  methods: {
    ...mapActions([
      "getUserData",
      "sendLogoutRequest",
      "sendLoginRequest123",
      "sendRegisterRequest12","CartCountRequest"
    ]),
  updateScroll() {
       this.scrollPosition = window.scrollY
    },
    // getUser() {
    //   this.getUserData().then((res) => {
    //     this.username = res.data.user.name;
    //   });
    // },
    Logout() {
      this.sendLogoutRequest().then((res) => {
        setTimeout(() => {
          location.reload();
        }, 3000);
      });
    },
    validateState(email) {
      const { $dirty, $error } = this.v$.details[email];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.details.$anyError) {
        this.sendLoginRequest123(this.details).then((response) => {
          
        });
      } else {
        this.loading = true;
        this.sendLoginRequest123(this.details)
          .then((response) => {
            console.log(response);
             window.location.href = "/dashboard";
             localStorage.setItem("popup_expirplan", 0);
          })
          .catch((error) => {
            
            // console.log("csichksahckhsak");
            this.loading = false;
            this.errorMessageVerified = localStorage.getItem(
              "errorMessageVerified"
            );this.errorMessageAlreadylogin = localStorage.getItem(
              "errorMessageAlreadylogin"
            );
            if (this.errorMessageVerified == "Email is not verified") {
              this.errorMessage = "Email is not verified";
            }else  if (this.errorMessageAlreadylogin == "Already login in other device") {
              this.errorMessage = "Already login in other device";
            } else {
              this.errorMessage = "Email or Password is not correct ";
            }
          });
      }
    },
   
    checkUsername() {
      this.disabledregister = 0;
      this.errorMessageEmaiAlready == null;
    },
    hideerrorPass() {
      this.disabledregister = 0;
      this.errorMessagePasswordInvalid = null;
    },
    onSubmitsignup() {
      this.v$.signupdetails.$touch();
      if (this.v$.signupdetails.$error) {
        return;
      } else {
        this.disabledregister = 1;
        this.loading = true;
        this.sendRegisterRequest12(this.signupdetails).then((response) => {
          this.loading = false;
          this.errorMessagePasswordInvalid = localStorage.getItem(
            "errorMessagePassword"
          );
          this.errorMessageEmaiAlready = localStorage.getItem("errorMessage");
          if (
            this.errorMessagePasswordInvalid == null ||
            this.errorMessageEmaiAlready == null
          ) {
            this.$router.push({ name: "Verification" });
          }
        });
      }
    },
    CartCount(){
      this.useridCountCart.userid= localStorage.getItem( "user");
      this.useridCountCart.token= localStorage.getItem( "token");
      this.CartCountRequest(this.useridCountCart).then((response) => {
        this.useridCountCart.cartcount=response.data.data[0];
      })
    },
    redirect() {
      this.$router.push("/");
    },
  },
};

</script>
<style >
.headerscroll{
  background-color: #1e38a7 !important;
}
#Cart {
  fill: #0d6efd !important;
}
.navbar.navbar-dark.navbar-nav.nav-link {
  color: unset !important;
  text-decoration: none !important;
}
.nav-link {
  color: white !important;
}
.text-size {
  font-size: 1.3rem;
}
.text-size-logo {
  font-size: 2rem;
}
.user-css {
  text-decoration: none;
  color: #fa2b56;
  font-size: 1.5rem;
}

.loginreg {
  background-color: #fa2b56;
  border-radius: 10px;
}
.bg-pink {
  background-color: #fa2b56;
}
@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");
.demo {
  display: none;
}

.button:hover span {
  padding-right: 25px;
}
.details-button {
  padding-right: 0px !important; /* Remove default padding */
}

.details-button span {
  max-width: 0;
  display: inline-flex;
  white-space: nowrap;
  transition: max-width 0.5s, padding-right 0.45s; /* Transition width and padding to avoid "popping" */
  overflow: hidden;
  padding-left: 5px; /* Add default button padding */
}

.details-button:hover span,
.details-button:focus span {
  max-width: 100px; /* how far the text can expand, adjust based on content */
  padding-right: 10px !important; /* Adds spacing on the right of the text when expanded */
}

/* Demlo css  */

.menuhoverd {
  color: white;
  outline: none;
  cursor: pointer;
}

/* EIGHTH BUTTON */

.menuhoverd {
  transition: all 0.5s ease-in-out;
  padding-right: 1rem;
}
.menuhoverd:hover {
  color: #fa2b56;
  /* background-color: #FA2B56; */
}
.menuhoverd:hover .cart-counter {
  color: #fff;
}

.menuhoverd:hover span {
  transition: all 0.2s ease-in-out;
  border-bottom: white 1px solid;
  color: #fa2b56;
}
.menuhoverd:hover a {
  transition: all 0.5s ease-in-out;

  color: #fa2b56;

  border: none;
}
.menuhoverd .nav-link {
  padding-bottom: 0px !important;
}

a {
  color: white;
  text-decoration-line: none;
}
.header_css {
  /* box-shadow: 0 0 10px rgb(0 0 0 / 18%); */
  position: fixed;
  z-index: 2;
  background: transparent;
  animation: smoothScroll 1s forwards;
}
.btn-css {
  padding: 0.3rem 1rem;
  border: 1px solid #fff;
  border-radius: 1rem 1rem;
}

.display-inline {
  display: inline;
}
.user-icon-default {
  padding: 5px;
  background: #fa2b56;
  border-radius: 3rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.user-name:hover {
  /* color: #FA2B56; */
  transition: all 0.2s ease-in-out;
  border-bottom: white 1px solid;
  color: #fa2b56;
}
router-link {
  text-decoration-line: none;
}
.cart-counter{
  
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    border-radius:100%;
}
</style>
